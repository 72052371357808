import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/SelectorsForm.module.css';
import styles_input from '@styles/InputContainer.module.css';
import { MdOutlineLocationOn, MdSearch } from 'react-icons/md';
import Floated from '@components/Floated';
import { SelectorCityOptions } from '@utils/constans';
import { useSearchParams } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import { locationOptions } from './constants';

function SelectorsForm({
    placeholderSearch = '关键字搜索',
    placeholderTag = '选择地点',
    options = [],
    tagOptions = [],
    handleSubmit,
    replace = true,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pickerActive, setPickerActive] = useState(false);
    const [selectorOptions, setSelectorOptions] = useState([...SelectorCityOptions]);
    const [selectorName, setSelectorName] = useState('');
    const textRef = useRef();

    useEffect(() => {
        if (options.length > 0) {
            setSelectorOptions(options);
        }
    }, [options]);

    // Update display text and selector name
    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const selector = params.get('selector');
        let name;
        if (selector) {
            if (!!selector) {
                const item = selectorOptions.find((item) => item.value == selector);
                if (item) {
                    name = item.name;
                }
            }
        }
        setSelectorName(name);
        const text = params.get('text');
        if (text) {
            textRef.current.value = text;
        }
    }, [searchParams]);

    const handleOnSelect = (e) => {
        const selector = e.target.attributes.value.nodeValue;
        const params = new URLSearchParams(searchParams);
        params.set('selector', selector || '');
        setSearchParams(params, { replace: replace });
        setPickerActive(false);
    };

    const handleOnTagClick = (name, selector) => {
        const params = new URLSearchParams(searchParams);
        const sel = params.get('selector');
        if (selector == sel) {
            selector = '';
            params.delete('selector');
        } else {
            params.set('selector', selector);
        }
        setSearchParams(params, { replace: replace });
    };

    const handleOoTextChange = (e) => {
        const text = textRef.current.value;
        const params = new URLSearchParams(searchParams);
        if (!!text) {
            params.set('text', text || '');
        } else {
            params.delete('text');
        }
        setSearchParams(params, { replace: replace });
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputs_container}>
                <div className={`${styles_input.input_container} ${styles.selector_container}`}>
                    <MdOutlineLocationOn className={styles_input.icon} />
                    <input className={styles_input.input} placeholder={placeholderTag} defaultValue={selectorName} />
                    <button className={styles_input.trigger_btn} onClick={() => setPickerActive(true)} />
                    <Floated active={pickerActive} setActive={setPickerActive} fluid>
                        <div className={styles.dropdown_container}>
                            {selectorOptions?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={styles.dropdown_item_container}
                                        onClick={handleOnSelect}
                                        value={item.value}
                                        aria-selected={item.name == selectorName && 'true'}
                                    >
                                        {item.name}
                                    </div>
                                );
                            })}
                        </div>
                    </Floated>
                </div>
                <div className={`${styles_input.input_container} ${styles.text_container}`}>
                    <MdSearch className={styles_input.icon} />
                    <input
                        className={styles_input.input}
                        placeholder={placeholderSearch}
                        onChange={handleOoTextChange}
                        ref={textRef}
                    />
                </div>

                {handleSubmit && (
                    <div className={styles.action_container}>
                        <button className="primary_btn" onClick={handleSubmit}>
                            查询
                        </button>
                    </div>
                )}
            </div>

            {tagOptions.length > 0 && (
                <div className={styles.bottom_container}>
                    <label htmlFor="">推荐城市</label>
                    <div className={styles.tags_container}>
                        {tagOptions?.map((item, index) => {
                            const params = new URLSearchParams(searchParams);
                            const selector = params.get('selector');
                            const selected = selector == item.value;
                            return (
                                <div
                                    key={index}
                                    className={styles.tag_container}
                                    onClick={() => handleOnTagClick(item.name, item.value)}
                                    aria-selected={selected && 'true'}
                                >
                                    {item.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

SelectorsForm.propTypes = {
    placeholderSearch: PropTypes.string,
    placeholderTag: PropTypes.string,
    options: PropTypes.array,
    tagOptions: PropTypes.array,
    handleSubmit: PropTypes.func,
    replace: PropTypes.bool,
};

export default SelectorsForm;
